











































































import mixins from 'vue-typed-mixins'
import Navigation from '@/calendesk/mixins/Navigation'
import LoggedUserMenu from '@/calendesk/sections/navbar/components/LoggedUserMenu.vue'
import AdditionalPagesMenu from '@/calendesk/sections/navbar/components/AdditionalPagesMenu.vue'
import Page from '@/calendesk/models/DTO/Response/Page'
export default mixins(Navigation).extend({
  name: 'Navbar3',
  methods: {
    buttonBackground (page: Page) {
      return {
        background:
          (this.currentPage && this.currentPage.uuid === page.uuid)
            ? this.draftConfiguration.wb_color_primary__color__
            : 'transparent'
      }
    }
  },
  components: { AdditionalPagesMenu, LoggedUserMenu }
})
